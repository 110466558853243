<template>
    <path d="m272 48.1v51.6h-96c-26.5 0-48 21.5-48 48v88.6c0 26.5 21.5 48 48 48h96v51.6c0 42.6 51.7 64.2 81.9 33.9l144-143.9c18.7-18.7 18.7-49.1 0-67.9l-144-144c-30.1-29.9-81.9-8.6-81.9 34.1zm192 144-144 144v-99.7h-144v-88.6h144v-99.7l144 144zm-368-192h84c6.6 0 12 5.4 12 12v24c0 6.6-5.4 12-12 12h-84c-26.5 0-48 21.5-48 48v192c0 26.5 21.5 48 48 48h84c6.6 0 12 5.4 12 12v24c0 6.6-5.4 12-12 12h-84c-53 0-96-43-96-96v-192c0-53 43-96 96-96z"/>
</template>

<script>
export default {
    setup(props, { emit }) {
        emit('dimensions', { width: 511.9, height: 384.1 });
    },
    emits: ['dimensions'],
};
</script>
